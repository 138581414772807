 import chartOptions from '../chart/chart_options';
 import common from '../component/common.js';
 import moment from "moment";

 const getSantralComparisonMinuteChart = (self, formdata, _rawData, inverter_Measures,
   string_Measures, stepTime) => new Promise((resolve) => {
 
   if (_rawData[0].data.count > formdata.string.condiniton.maxcount) {
     self.snackbar = true;
     self.color = 'red';
     self.text = `Stringbox veri sayısı ${formdata.string.condiniton.maxcount
      } den fazla `;
   }
   if (_rawData[1].data.count > formdata.inverter.condiniton.maxcount) {
     self.snackbar = true;
     self.color = 'red';
     self.text = `İnverter veri sayısı ${formdata.inverter.condiniton.maxcount
      } den fazla `;
   }

   const result = {};
   const dateList = [];
   const yAxis = [];
   const series = [];

   let sortType = 'datetimestamp';
   if (formdata.reportTypeModel === 'Minute') {
     sortType = 'datetimestamp';
   }
   if (formdata.reportTypeModel === 'Hourly') {
     sortType = 'Hour';
   }

   const tempData = {};
   let res1 = [];

   if (_rawData[0].data.data !== undefined && _rawData[0].data.success == 'true') {
     const tempTimeData = {};
     if (_rawData[0].data.data.length > 0) {
       res1 = _rawData[0].data.data.sort((a, b) => {
         if (a[sortType] < b[sortType]) return -1;
         if (a[sortType] > b[sortType]) return 1;
         return 0;
       });

       res1.forEach((item) => {
         let hh;
         let mm;


         const {
           santral,
           STRINGBOX
         } = item;

         item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
         item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm =
           `${item.MinuteGrup}`;
         const date = `${hh}:${mm}`;

         if (!tempTimeData[santral]) {
           tempTimeData[santral] = {};
         }


         if (!tempTimeData[santral][STRINGBOX]) {
           tempTimeData[santral][STRINGBOX] = {};
         }

         tempTimeData[santral][STRINGBOX][date] = item;
       });
     }

     if (res1.length > 0) {
       const santralList = formdata.string.condiniton.santral;
       const combinerBox = formdata.string.condiniton.STRINGBOX;

       santralList.forEach((santral) => {
         combinerBox.forEach((string) => {
           const minute = 0;
           const hour = 5;

           for (let i = hour; i <= 20; i++) {
             for (let x = minute; x < 60; x += stepTime) {
               let h;
               let m;
               i < 10 ? h = `0${i}` : h = `${i}`;
               x < 10 ? m = `0${x}` : m = `${x}`;
               const time = `${h}:${m}`;
               const obj = {};
               obj.date = time;

               if (dateList.indexOf(time) < 0) {
                 dateList.push(time);
               }

               let tempVal = 0;
               if (tempTimeData.hasOwnProperty(santral)) {
                 if (tempTimeData[santral].hasOwnProperty(string)) {
                   if (tempTimeData[santral][string].hasOwnProperty(time)) {
                     const tempValue = tempTimeData[santral][string][time];
                     string_Measures.forEach((ms) => {
                       const meas_ = `${santral}-${string}-${ms.text}`;
                       if (tempValue[ms.value] !== 0) {
                         if (!tempData.hasOwnProperty(meas_)) {
                           tempData[meas_] = [];
                         }
                         tempVal = common.setMeasures(ms, tempValue);

                         tempData[meas_].push(tempVal);
                       } else {
                         if (!tempData.hasOwnProperty(meas_)) {
                           tempData[meas_] = [];
                         }

                         tempData[meas_].push(0);
                       }
                     });
                   }
                 }
               } else {
                 string_Measures.forEach((ms) => {
                   const meas_ = `${santral}-${string}-${ms.text}`;
                   if (!tempData.hasOwnProperty(meas_)) {
                     tempData[meas_] = [];
                   }

                   tempData[meas_].push(0);
                 });
               }
             }
           }
         });
       });
     }
   }

   let res2 = [];

   if (_rawData[1].data.data !== undefined && _rawData[1].data.success == 'true') {
     const tempTimeData = {};

     if (_rawData[1].data.data.length > 0) {
       res2 = _rawData[1].data.data.sort((a, b) => {
         if (a[sortType] < b[sortType]) return -1;
         if (a[sortType] > b[sortType]) return 1;
         return 0;
       });

       res2.forEach((item) => {
         let hh;
         let mm;
         const {
           santral
         } = item;
         const {
           INVERTER
         } = item;
         item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
         item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm =
           `${item.MinuteGrup}`;
         const date = `${hh}:${mm}`;

         if (!tempTimeData[santral]) {
           tempTimeData[santral] = {};
         }
         if (!tempTimeData[santral][INVERTER]) {
           tempTimeData[santral][INVERTER] = {};
         }

         tempTimeData[santral][INVERTER][date] = item;
       });
     }

     if (res2.length > 0) {
       const santralList = formdata.inverter.condiniton.santral;
       const inverterList = formdata.inverter.condiniton.INVERTER;

       santralList.forEach((santral) => {
         inverterList.forEach((invert) => {
           const minute = 0;
           const hour = 5;

           for (let i = hour; i <= 20; i++) {
             for (let x = minute; x < 60; x += stepTime) {
               let h;
               let
                 m;
               i < 10 ? h = `0${i}` : h = `${i}`;
               x < 10 ? m = `0${x}` : m = `${x}`;
               const time = `${h}:${m}`;
               const obj = {};
               obj.date = time;

               if (dateList.indexOf(time) < 0) {
                 dateList.push(time);
               }

               let tempVal = 0;

               if (tempTimeData.hasOwnProperty(santral)) {
                 if (tempTimeData[santral].hasOwnProperty(invert)) {
                   if (tempTimeData[santral][invert].hasOwnProperty(time)) {
                     const tempValue = tempTimeData[santral][invert][time];

                     inverter_Measures.forEach((ms) => {
                       const meas_ = `${santral}-${invert}-${ms.text}`;
                       if (tempValue[ms.value] !== 0) {
                         if (!tempData.hasOwnProperty(meas_)) {
                           tempData[meas_] = [];
                         }

                         tempVal = common.setMeasures(ms, tempValue);

                         tempData[meas_].push(tempVal);
                       } else {
                         if (!tempData.hasOwnProperty(meas_)) {
                           tempData[meas_] = [];
                         }
                         tempData[meas_].push(0);
                       }
                     });
                   }
                 }
               } else {
                 inverter_Measures.forEach((ms) => {
                   const meas_ = `${santral}-${invert}-${ms.text}`;

                   if (!tempData.hasOwnProperty(meas_)) {
                     tempData[meas_] = [];
                   }
                   tempData[meas_].push(0);
                 });
               }
             }
           }
         });
       });
     }
   }

   if (res1.length > 0 || res2.length > 0) {
     let index = 0;

     Object.keys(tempData).forEach((keys) => {
       const obj = {
         name: keys,
         data: tempData[keys],
         type: 'line',
       };

       const name = keys.split('-');

       const yaxis = {
         type: 'value',
         //name: name[3],
       };


       if (index > 0) {
         yaxis.show = false;
       }
       index++;

       yAxis.push(yaxis);
       series.push(obj);
     });


     const options = chartOptions.line_options_multies_1('', 'santralModel', dateList,
       series, common.colors(), yAxis);

     result.options = options;
   }

   resolve(result);

 });

 const getSantralComparisonHourlyChart = (self, formdata, _rawData, inverter_Measures,
   string_Measures) => new Promise((resolve) => {


   if (_rawData[0].data.count > formdata.string.condiniton.maxcount) {
     self.snackbar = true;
     self.color = 'red';
     self.text = `Stringbox veri sayısı ${formdata.string.condiniton.maxcount
      } den fazla `;
   }
   if (_rawData[1].data.count > formdata.inverter.condiniton.maxcount) {
     self.snackbar = true;
     self.color = 'red';
     self.text = `İnverter veri sayısı ${formdata.inverter.condiniton.maxcount
      } den fazla `;
   }

   const result = {};
   const dateList = [];
   const yAxis = [];
   const series = [];

   let sortType = 'datetimestamp';
   if (formdata.reportTypeModel === 'Minute') {
     sortType = 'datetimestamp';
   }
   if (formdata.reportTypeModel === 'Hourly') {
     sortType = 'Hour';
   }

   const tempData = {};
   let res1 = [];

   if (_rawData[0].data.data !== undefined && _rawData[0].data.success == 'true') {
     const tempTimeData = {};
     if (_rawData[0].data.data.length > 0) {
       res1 = _rawData[0].data.data.sort((a, b) => {
         if (a[sortType] < b[sortType]) return -1;
         if (a[sortType] > b[sortType]) return 1;
         return 0;
       });

       res1.forEach((item) => {
         let hh;

         const {
           santral,
           STRINGBOX
         } = item;

         item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;

         const date = `${hh}`;

         if (!tempTimeData[santral]) {
           tempTimeData[santral] = {};
         }


         if (!tempTimeData[santral][STRINGBOX]) {
           tempTimeData[santral][STRINGBOX] = {};
         }

         tempTimeData[santral][STRINGBOX][date] = item;
       });
     }

     if (res1.length > 0) {
       const santralList = formdata.string.condiniton.santral;
       const combinerBox = formdata.string.condiniton.STRINGBOX;

       santralList.forEach((santral) => {
         combinerBox.forEach((string) => {

           const hour = 5;

           for (let i = hour; i <= 20; i++) {

             let h;
             i < 10 ? h = `0${i}` : h = `${i}`;

             const time = `${h}`;
             const obj = {};
             obj.date = time;

             if (dateList.indexOf(time) < 0) {
               dateList.push(time);
             }

             let tempVal = 0;
             if (tempTimeData.hasOwnProperty(santral)) {
               if (tempTimeData[santral].hasOwnProperty(string)) {
                 if (tempTimeData[santral][string].hasOwnProperty(time)) {
                   const tempValue = tempTimeData[santral][string][time];
                   string_Measures.forEach((ms) => {
                     const meas_ = `${santral}-${string}-${ms.text}`;
                     if (tempValue[ms.value] !== 0) {
                       if (!tempData.hasOwnProperty(meas_)) {
                         tempData[meas_] = [];
                       }
                       tempVal = common.setMeasures(ms, tempValue);

                       tempData[meas_].push(tempVal);
                     } else {
                       if (!tempData.hasOwnProperty(meas_)) {
                         tempData[meas_] = [];
                       }

                       tempData[meas_].push(0);
                     }
                   });
                 }
               }
             } else {
               string_Measures.forEach((ms) => {
                 const meas_ = `${santral}-${string}-${ms.text}`;
                 if (!tempData.hasOwnProperty(meas_)) {
                   tempData[meas_] = [];
                 }

                 tempData[meas_].push(0);
               });
             }

           }
         });
       });
     }
   }

   let res2 = [];

   if (_rawData[1].data.data !== undefined && _rawData[1].data.success == 'true') {
     const tempTimeData = {};

     if (_rawData[1].data.data.length > 0) {
       res2 = _rawData[1].data.data.sort((a, b) => {
         if (a[sortType] < b[sortType]) return -1;
         if (a[sortType] > b[sortType]) return 1;
         return 0;
       });

       res2.forEach((item) => {
         let hh;
         let mm;
         const {
           santral,
           INVERTER
         } = item;

         item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;

         const date = `${hh}`;

         if (!tempTimeData[santral]) {
           tempTimeData[santral] = {};
         }
         if (!tempTimeData[santral][INVERTER]) {
           tempTimeData[santral][INVERTER] = {};
         }

         tempTimeData[santral][INVERTER][date] = item;
       });
     }

     if (res2.length > 0) {
       const santralList = formdata.inverter.condiniton.santral;
       const inverterList = formdata.inverter.condiniton.INVERTER;

       santralList.forEach((santral) => {
         inverterList.forEach((invert) => {

           const hour = 5;

           for (let i = hour; i <= 20; i++) {
             let h;

             i < 10 ? h = `0${i}` : h = `${i}`;

             const time = `${h}`;
             const obj = {};
             obj.date = time;

             if (dateList.indexOf(time) < 0) {
               dateList.push(time);
             }

             let tempVal = 0;

             if (tempTimeData.hasOwnProperty(santral)) {
               if (tempTimeData[santral].hasOwnProperty(invert)) {
                 if (tempTimeData[santral][invert].hasOwnProperty(time)) {
                   const tempValue = tempTimeData[santral][invert][time];

                   inverter_Measures.forEach((ms) => {
                     const meas_ = `${santral}-${invert}-${ms.text}`;
                     if (tempValue[ms.value] !== 0) {
                       if (!tempData.hasOwnProperty(meas_)) {
                         tempData[meas_] = [];
                       }

                       tempVal = common.setMeasures(ms, tempValue);
                       tempData[meas_].push(tempVal);
                     } else {
                       if (!tempData.hasOwnProperty(meas_)) {
                         tempData[meas_] = [];
                       }
                       tempData[meas_].push(0);
                     }
                   });
                 }
               }
             } else {
               inverter_Measures.forEach((ms) => {
                 const meas_ = `${santral}-${invert}-${ms.text}`;

                 if (!tempData.hasOwnProperty(meas_)) {
                   tempData[meas_] = [];
                 }
                 tempData[meas_].push(0);
               });
             }

           }
         });
       });
     }
   }

   if (res1.length > 0 || res2.length > 0) {
     let index = 0;

     Object.keys(tempData).forEach((keys) => {
       const obj = {
         name: keys,
         data: tempData[keys],
         type: 'line',
       };

       const name = keys.split('-');

       const yaxis = {
         type: 'value',
         //name: name[3],
       };


       if (index > 0) {
         yaxis.show = false;
       }
       index++;

       yAxis.push(yaxis);
       series.push(obj);
     });


     const options = chartOptions.line_options_multies_1('', 'santralModel', dateList,
       series, common.colors(), yAxis);

     result.options = options;
   }

   resolve(result);

 });



 const getSantralComparisonDailyChart = (self, formdata, _rawData, inverter_Measures,
   string_Measures) => new Promise((resolve) => {


   if (_rawData[0].data.count > formdata.string.condiniton.maxcount) {
     self.snackbar = true;
     self.color = 'red';
     self.text = `Stringbox veri sayısı ${formdata.string.condiniton.maxcount
      } den fazla `;
   }
   if (_rawData[1].data.count > formdata.inverter.condiniton.maxcount) {
     self.snackbar = true;
     self.color = 'red';
     self.text = `İnverter veri sayısı ${formdata.inverter.condiniton.maxcount
      } den fazla `;
   }

   const result = {};
   const dateList = [];
   const yAxis = [];
   const series = [];
   let sortType = 'Day';


   const tempData = {};
   let res1 = [];

   let year = formdata.string.condiniton.year;
   let month = formdata.string.condiniton.month;
   let mm
   month < 10 ? mm = `0${month}` : mm = `${month}`;

   let startDate = `${year}-${mm}-01`;

   if (_rawData[0].data.data !== undefined && _rawData[0].data.success == 'true') {
     const tempTimeData = {};
     if (_rawData[0].data.data.length > 0) {
       res1 = _rawData[0].data.data.sort((a, b) => {
         if (a[sortType] < b[sortType]) return -1;
         if (a[sortType] > b[sortType]) return 1;
         return 0;
       });

       res1.forEach((item) => {
         let mm, dd;

         const {
           santral,
           STRINGBOX
         } = item;

         item.Month < 10 ? mm = `0${item.Month}` : mm = `${item.Month}`;
         item.Day < 10 ? dd = `0${item.Day}` : dd = `${item.Day}`;

         const date = `${item.Year}-${mm}-${dd}`;

         if (!tempTimeData[santral]) {
           tempTimeData[santral] = {};
         }


         if (!tempTimeData[santral][STRINGBOX]) {
           tempTimeData[santral][STRINGBOX] = {};
         }

         tempTimeData[santral][STRINGBOX][date] = item;
       });

     }



     if (res1.length > 0) {
       const santralList = formdata.string.condiniton.santral;
       const combinerBox = formdata.string.condiniton.STRINGBOX;

       santralList.forEach((santral) => {
         combinerBox.forEach((string) => {


           for (let d = 1; d < 32; d++) {
             const time = moment(startDate).add(d, 'day').format('YYYY-MM-DD');

             const obj = {};
             obj.date = time;

             if (dateList.indexOf(time) < 0) {
               dateList.push(time);
             }

             let tempVal = 0;
             if (tempTimeData.hasOwnProperty(santral)) {
               if (tempTimeData[santral].hasOwnProperty(string)) {
                 if (tempTimeData[santral][string].hasOwnProperty(time)) {
                   const tempValue = tempTimeData[santral][string][time];
                   string_Measures.forEach((ms) => {
                     const meas_ = `${santral}-${string}-${ms.text}`;
                     if (tempValue[ms.value] !== 0) {
                       if (!tempData.hasOwnProperty(meas_)) {
                         tempData[meas_] = [];
                       }


                       tempVal = common.setMeasures(ms, tempValue);

                       tempData[meas_].push(tempVal);
                     } else {
                       if (!tempData.hasOwnProperty(meas_)) {
                         tempData[meas_] = [];
                       }

                       tempData[meas_].push(0);
                     }
                   });
                 }
               }
             } else {
               string_Measures.forEach((ms) => {
                 const meas_ = `${santral}-${string}-${ms.text}`;
                 if (!tempData.hasOwnProperty(meas_)) {
                   tempData[meas_] = [];
                 }

                 tempData[meas_].push(0);
               });
             }

           }
         });
       });
     }
   }


   let res2 = [];


   if (_rawData[1].data.data !== undefined && _rawData[1].data.success == 'true') {
     const tempTimeData = {};

     if (_rawData[1].data.data.length > 0) {
       res2 = _rawData[1].data.data.sort((a, b) => {
         if (a[sortType] < b[sortType]) return -1;
         if (a[sortType] > b[sortType]) return 1;
         return 0;
       });

       res2.forEach((item) => {

         const {
           santral,
           INVERTER
         } = item;

         let mm, dd;

         item.Month < 10 ? mm = `0${item.Month}` : mm = `${item.Month}`;
         item.Day < 10 ? dd = `0${item.Day}` : dd = `${item.Day}`;

         const date = `${item.Year}-${mm}-${dd}`;

         if (!tempTimeData[santral]) {
           tempTimeData[santral] = {};
         }
         if (!tempTimeData[santral][INVERTER]) {
           tempTimeData[santral][INVERTER] = {};
         }

         tempTimeData[santral][INVERTER][date] = item;
       });

     }

     if (res2.length > 0) {
       const santralList = formdata.inverter.condiniton.santral;
       const inverterList = formdata.inverter.condiniton.INVERTER;

       santralList.forEach((santral) => {
         inverterList.forEach((invert) => {


           for (let d = 1; d < 32; d++) {
             const time = moment(startDate).add(d, 'day').format('YYYY-MM-DD');

             const obj = {};
             obj.date = time;

             if (dateList.indexOf(time) < 0) {
               dateList.push(time);
             }

             let tempVal = 0;

             if (tempTimeData.hasOwnProperty(santral)) {
               if (tempTimeData[santral].hasOwnProperty(invert)) {
                 if (tempTimeData[santral][invert].hasOwnProperty(time)) {
                   const tempValue = tempTimeData[santral][invert][time];

                   inverter_Measures.forEach((ms) => {
                     const meas_ = `${santral}-${invert}-${ms.text}`;
                     if (tempValue[ms.value] !== 0) {
                       if (!tempData.hasOwnProperty(meas_)) {
                         tempData[meas_] = [];
                       }
                       tempVal = common.setMeasures(ms, tempValue);

                       tempData[meas_].push(tempVal);
                     } else {
                       if (!tempData.hasOwnProperty(meas_)) {
                         tempData[meas_] = [];
                       }
                       tempData[meas_].push(0);
                     }
                   });
                 }
               }
             } else {
               inverter_Measures.forEach((ms) => {
                 const meas_ = `${santral}-${invert}-${ms.text}`;

                 if (!tempData.hasOwnProperty(meas_)) {
                   tempData[meas_] = [];
                 }
                 tempData[meas_].push(0);
               });
             }

           }
         });
       });
     }
   }


   if (res1.length > 0 || res2.length > 0) {
     let index = 0;

     Object.keys(tempData).forEach((keys) => {
       const obj = {
         name: keys,
         data: tempData[keys],
         type: 'line',
       };

       const name = keys.split('-');

       const yaxis = {
         type: 'value',
         //name: name[3],
       };


       if (index > 0) {
         yaxis.show = false;
       }
       index++;

       yAxis.push(yaxis);
       series.push(obj);
     });


     const options = chartOptions.line_options_multies_1('', 'santralModel', dateList,
       series, common.colors(), yAxis);

     result.options = options;
   }

   resolve(result);

 });

 const getSantralComparisonMonthlyChart = (self, formdata, _rawData, inverter_Measures,
   string_Measures) => new Promise((resolve) => {


   if (_rawData[0].data.count > formdata.string.condiniton.maxcount) {
     self.snackbar = true;
     self.color = 'red';
     self.text = `Stringbox veri sayısı ${formdata.string.condiniton.maxcount
      } den fazla `;
   }
   if (_rawData[1].data.count > formdata.inverter.condiniton.maxcount) {
     self.snackbar = true;
     self.color = 'red';
     self.text = `İnverter veri sayısı ${formdata.inverter.condiniton.maxcount
      } den fazla `;
   }

   const result = {};
   const dateList = [];
   const yAxis = [];
   const series = [];
   let sortType = 'Month';


   const tempData = {};
   let res1 = [];

   let date_List = formdata.string.condiniton.date;

   if (_rawData[0].data.data !== undefined && _rawData[0].data.success == 'true') {
     const tempTimeData = {};
     if (_rawData[0].data.data.length > 0) {
       res1 = _rawData[0].data.data.sort((a, b) => {
         if (a[sortType] < b[sortType]) return -1;
         if (a[sortType] > b[sortType]) return 1;
         return 0;
       });

       res1.forEach((item) => {

         const {
           santral,
           STRINGBOX
         } = item;
         let mm;

         item.Month < 10 ? mm = `0${item.Month}` : mm = `${item.Month}`;
         const date = `${item.Year}-${mm}`;


         if (!tempTimeData[santral]) {
           tempTimeData[santral] = {};
         }


         if (!tempTimeData[santral][STRINGBOX]) {
           tempTimeData[santral][STRINGBOX] = {};
         }

         tempTimeData[santral][STRINGBOX][date] = item;
       });


     }

     if (res1.length > 0) {
       const santralList = formdata.string.condiniton.santral;
       const combinerBox = formdata.string.condiniton.STRINGBOX;

       santralList.forEach((santral) => {
         combinerBox.forEach((string) => {

           date_List.forEach((item) => {
             item.month.forEach((m) => {

               const _m = m < 10 ? `0${m}` : m;
               const time = `${item.year}-${_m}`;

               const obj = {};
               obj.date = time;

               if (dateList.indexOf(time) < 0) {
                 dateList.push(time);
               }

               let tempVal = 0;
               if (tempTimeData.hasOwnProperty(santral)) {
                 if (tempTimeData[santral].hasOwnProperty(string)) {
                   if (tempTimeData[santral][string].hasOwnProperty(time)) {
                     const tempValue = tempTimeData[santral][string][time];
                     string_Measures.forEach((ms) => {
                       if (tempValue[ms.value] !== 0) {
                         tempVal = common.setMeasures(ms, tempValue);
                       }
                     });
                   }
                 }
               }


               string_Measures.forEach((ms) => {
                 const meas_ = `${santral}-${string}-${ms.text}`;
                 if (!tempData.hasOwnProperty(meas_)) {
                   tempData[meas_] = [];
                 }

                 tempData[meas_].push(tempVal);
               });


             })
           })
         });
       });
     }
   }

   let res2 = [];


   if (_rawData[1].data.data !== undefined && _rawData[1].data.success == 'true') {
     const tempTimeData = {};

     if (_rawData[1].data.data.length > 0) {
       res2 = _rawData[1].data.data.sort((a, b) => {
         if (a[sortType] < b[sortType]) return -1;
         if (a[sortType] > b[sortType]) return 1;
         return 0;
       });

       res2.forEach((item) => {

         const {
           santral,
           INVERTER
         } = item;

         let mm;

         item.Month < 10 ? mm = `0${item.Month}` : mm = `${item.Month}`;
         const date = `${item.Year}-${mm}`;


         if (!tempTimeData[santral]) {
           tempTimeData[santral] = {};
         }
         if (!tempTimeData[santral][INVERTER]) {
           tempTimeData[santral][INVERTER] = {};
         }

         tempTimeData[santral][INVERTER][date] = item;
       });


     }

     if (res2.length > 0) {
       const santralList = formdata.inverter.condiniton.santral;
       const inverterList = formdata.inverter.condiniton.INVERTER;

       santralList.forEach((santral) => {
         inverterList.forEach((invert) => {

           date_List.forEach((item) => {
             item.month.forEach((m) => {

               const _m = m < 10 ? `0${m}` : m;
               const time = `${item.year}-${_m}`;

               const obj = {};
               obj.date = time;

               if (dateList.indexOf(time) < 0) {
                 dateList.push(time);
               }

               let tempVal = 0;

               if (tempTimeData.hasOwnProperty(santral)) {
                 if (tempTimeData[santral].hasOwnProperty(invert)) {
                   if (tempTimeData[santral][invert].hasOwnProperty(time)) {
                     const tempValue = tempTimeData[santral][invert][time];
                     inverter_Measures.forEach((ms) => {
                       if (tempValue[ms.value] !== 0) {
                         tempVal = common.setMeasures(ms, tempValue);
                       }
                     });
                   }
                 }
               }
               inverter_Measures.forEach((ms) => {
                 const meas_ = `${santral}-${invert}-${ms.text}`;

                 if (!tempData.hasOwnProperty(meas_)) {
                   tempData[meas_] = [];
                 }
                 tempData[meas_].push(tempVal);
               });


             })
           })
         });
       });
     }
   }

   if (res1.length > 0 || res2.length > 0) {
     let index = 0;

     Object.keys(tempData).forEach((keys) => {
       const obj = {
         name: keys,
         data: tempData[keys],
         type: 'line',
       };

       const name = keys.split('-');

       const yaxis = {
         type: 'value',
         //name: name[3],
       };


       if (index > 0) {
         yaxis.show = false;
       }
       index++;

       yAxis.push(yaxis);
       series.push(obj);
     });

     const options = chartOptions.line_options_multies_1('', 'santralModel', dateList,
       series, common.colors(), yAxis);

     result.options = options;
   }

   resolve(result);

 });


 const getSantralComparisonYearlyChart = (self, formdata, _rawData, inverter_Measures,
   string_Measures) => new Promise((resolve) => {


   if (_rawData[0].data.count > formdata.string.condiniton.maxcount) {
     self.snackbar = true;
     self.color = 'red';
     self.text = `Stringbox veri sayısı ${formdata.string.condiniton.maxcount
      } den fazla `;
   }
   if (_rawData[1].data.count > formdata.inverter.condiniton.maxcount) {
     self.snackbar = true;
     self.color = 'red';
     self.text = `İnverter veri sayısı ${formdata.inverter.condiniton.maxcount
      } den fazla `;
   }

   const result = {};
   const dateList = [];
   const yAxis = [];
   const series = [];
   let sortType = 'Month';


   const tempData = {};
   let res1 = [];

   let date_List = formdata.string.condiniton.date;

   if (_rawData[0].data.data !== undefined && _rawData[0].data.success == 'true') {
     const tempTimeData = {};
     if (_rawData[0].data.data.length > 0) {
       res1 = _rawData[0].data.data.sort((a, b) => {
         if (a[sortType] < b[sortType]) return -1;
         if (a[sortType] > b[sortType]) return 1;
         return 0;
       });

       res1.forEach((item) => {

         const {
           santral,
           STRINGBOX,
           Year
         } = item;

         const date = Year;

         if (!tempTimeData[santral]) {
           tempTimeData[santral] = {};
         }


         if (!tempTimeData[santral][STRINGBOX]) {
           tempTimeData[santral][STRINGBOX] = {};
         }

         tempTimeData[santral][STRINGBOX][date] = item;
       });

     }

     if (res1.length > 0) {
       const santralList = formdata.string.condiniton.santral;
       const combinerBox = formdata.string.condiniton.STRINGBOX;

       santralList.forEach((santral) => {
         combinerBox.forEach((string) => {

           date_List.forEach((item) => {

             const time = item;
             const obj = {};
             obj.date = time;

             if (dateList.indexOf(time) < 0) {
               dateList.push(time);
             }

             let tempVal = 0;
             if (tempTimeData.hasOwnProperty(santral)) {
               if (tempTimeData[santral].hasOwnProperty(string)) {
                 if (tempTimeData[santral][string].hasOwnProperty(time)) {
                   const tempValue = tempTimeData[santral][string][time];
                   string_Measures.forEach((ms) => {
                     if (tempValue[ms.value] !== 0) {
                       tempVal = common.setMeasures(ms, tempValue);
                     }
                   });
                 }
               }
             }


             string_Measures.forEach((ms) => {
               const meas_ = `${santral}-${string}-${ms.text}`;
               if (!tempData.hasOwnProperty(meas_)) {
                 tempData[meas_] = [];
               }

               tempData[meas_].push(tempVal);
             });



           })
         });
       });
     }
   }




   let res2 = [];


   if (_rawData[1].data.data !== undefined && _rawData[1].data.success == 'true') {
     const tempTimeData = {};

     if (_rawData[1].data.data.length > 0) {
       res2 = _rawData[1].data.data.sort((a, b) => {
         if (a[sortType] < b[sortType]) return -1;
         if (a[sortType] > b[sortType]) return 1;
         return 0;
       });

       res2.forEach((item) => {

         const {
           santral,
           INVERTER,
           Year
         } = item;

         const date = Year;

         if (!tempTimeData[santral]) {
           tempTimeData[santral] = {};
         }
         if (!tempTimeData[santral][INVERTER]) {
           tempTimeData[santral][INVERTER] = {};
         }

         tempTimeData[santral][INVERTER][date] = item;
       });


     }

     if (res2.length > 0) {
       const santralList = formdata.inverter.condiniton.santral;
       const inverterList = formdata.inverter.condiniton.INVERTER;

       santralList.forEach((santral) => {
         inverterList.forEach((invert) => {

           date_List.forEach((item) => {

             const time = item;
             const obj = {};
             obj.date = time;

             if (dateList.indexOf(time) < 0) {
               dateList.push(time);
             }

             let tempVal = 0;

             if (tempTimeData.hasOwnProperty(santral)) {
               if (tempTimeData[santral].hasOwnProperty(invert)) {
                 if (tempTimeData[santral][invert].hasOwnProperty(time)) {
                   const tempValue = tempTimeData[santral][invert][time];
                   inverter_Measures.forEach((ms) => {
                     if (tempValue[ms.value] !== 0) {
                       tempVal = common.setMeasures(ms, tempValue);
                     }
                   });
                 }
               }
             }
             inverter_Measures.forEach((ms) => {
               const meas_ = `${santral}-${invert}-${ms.text}`;

               if (!tempData.hasOwnProperty(meas_)) {
                 tempData[meas_] = [];
               }
               tempData[meas_].push(tempVal);
             });

           })
         });
       });
     }
   }

   if (res1.length > 0 || res2.length > 0) {
     let index = 0;

     Object.keys(tempData).forEach((keys) => {
       const obj = {
         name: keys,
         data: tempData[keys],
         type: 'line',
       };

       const name = keys.split('-');

       const yaxis = {
         type: 'value',
         //name: name[3],
       };


       if (index > 0) {
         yaxis.show = false;
       }
       index++;

       yAxis.push(yaxis);
       series.push(obj);
     });

     const options = chartOptions.line_options_multies_1('', 'santralModel', dateList,
       series, common.colors(), yAxis);

     result.options = options;
   }

   resolve(result);

 });


 export default {

   getSantralComparisonMinuteChart,
   getSantralComparisonHourlyChart,
   getSantralComparisonDailyChart,
   getSantralComparisonMonthlyChart,
   getSantralComparisonYearlyChart
 };
